<template>
    
    <div class="searchOverlay d-block d-lg-none" @click="toggleSearch">
        <div class="row justify-content-center  align-items-center" style="height: 100%;">
            <div class="col-11">
                <div class="input-group">
                    <input type="text" class="form-control" @keyup.enter="search" ref="mobileSearch" id="mobileSearch" placeholder="Search..." :value="this.$store.state.searchTerm">
                    <div class="input-group-append clickable" id="btnContainer">
                        <i class="bi bi-arrow-right-circle-fill" @click="search" id="mobileSearchBtn"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "SearchOverlay",
    methods:
    {
        search()
        {
            var searchTerm = this.$refs.mobileSearch.value;
            if (searchTerm.length > 1)
            {
                this.$emit('search', searchTerm);
                console.log("Searching for: " + searchTerm);
                this.$emit('toggleSearch');
            }
            
        },
        toggleSearch(event)
        {
            console.log("Toggle search " + event.target.id);
            if (event.target.id != "mobileSearch" && event.target.id != "btnContainer" && event.target.id != "mobileSearchBtn")
            {
                this.$emit('toggleSearch');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss"; 

    .searchOverlay
    {
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        pointer-events: all;
    }

    #btnContainer
    {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 20;
    }

    i
    {
        color: #0077ED;
        font-size: 50px;
        margin-top: -20px;
        left: -10px;
        position: relative;
        z-index: 15;
    }
    #mobileSearch
    {
        height: 70px;
        font-family: "Poppins";
        font-size: 22px;
        border-radius: $custom-border-radius;
        border: none;
        padding-left: 35px;
        z-index: 10;
        pointer-events: all;
    }
</style>