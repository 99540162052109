<template>

  <div class="container-fluid">

    <div class="row justify-content-center" id="mainRow" @mousewheel="passWheel">

      <transition name="slide-fade-reverse" mode="out-in">
        <LeftNav ref="leftNav" @home="home" v-if="!isIntro" />
      </transition>

      <div class="col-12 col-lg-6 mainView" id="mainView" ref="mainView">
        
        <MobileNav @toggleSearch="toggleSearch" @home="home" v-if="!isIntro"></MobileNav>
        
        <p class="smallDisclaimer mt-1" style="text-align: center !important; margin-bottom: 0px;">The Exchange Pod has been developed under the direction and funding of Novartis Pharmaceuticals UK Ltd. in collaboration with UK healthcare professionals and is classified as promotional under the Association of British Pharmaceutical Industry (APBI) Code</p>
        <p class="smallDisclaimer mt-1 mb-0 pb-0" style="text-align: center !important;">This site is intended for UK healthcare professionals and other relevant decision makers only. If you are a member of the public, please <a href="https://www.health.novartis.co.uk/public">click here</a></p>
        <p class="smallDisclaimer mt-1" style="text-align: center !important;"><a href="https://www.health.novartis.co.uk/sites/health.novartis.co.uk/files/adakveo-pi.pdf" target="_blank">Prescribing information</a></p>
        <BackButton @back="back" :class="{hidden: (isHome || isIntro)}" class="backBtn"></BackButton>

        <transition name="fade" mode="out-in">
         <div class="row justify-content-center mb-5" v-if="$route.name == 'intro-text-view' || $route.name == 'intro-animation'">
            <div class="col-4">
                <img src="./assets/img/left-nav-pod-logo.png" alt="POD Logo" class="img-fluid" />
            </div>
          </div>
        </transition>

        <router-view v-slot="{ Component, route }" >
          <transition :name="myTransition" mode="out-in">
            <component :is="Component" ref="theComponent" :key="route.path" 
            @setAudioFile="setAudioFile" 
            @pauseAudio="pauseAudio" 
            @playAudio="playAudio"
            @back10="back10" 
            @forward10="forward10"
            @setAudioPosition="setAudioPosition"
            @changeSpeed="changeSpeed"
            @toggleSearch="toggleSearch"
            @showMiniPlayer="showMiniPlayer"
            @nextIntro="nextIntro"
            @nextTrack="nextTrack"
            @previousTrack="previousTrack"
            @setApprovalCode="setApprovalCode" />
          </transition>
        </router-view>

        <transition name="fade" mode="out-in">
          <!-- v-if="!isIntro"-->
          <FooterView ref="footerView"></FooterView>
        </transition>
        <!--<transition name="fade" mode="out-in">
          <MiniFooterView v-if="isIntro"></MiniFooterView>
        </transition>-->

      <MiniMediaPlayer ref="miniMediaPlayer" 
      :audioTitle="audioTitle"
      @pauseAudio="pauseAudio"
      @setAudioPosition="setAudioPosition"
      @playAudio="playAudio" ></MiniMediaPlayer> 
               
      </div>

      <transition name="slide-fade" mode="out-in">
        <RightNav ref="rightNav" @search="search" v-if="!isIntro" />
      </transition>
    </div>

    <audio id="audioPlayer" controls ref="audioPlayer" style="position: absolute; top: 100px; left: 200px; z-index: 10; display: none;" 
    @pause="pauseAudio()" 
    @timeupdate="updatePlayer()"
    @ended="audioEnded()">
      <source :src="audioFile" type="audio/mpeg">
    </audio>

    <SearchOverlay v-if="searchVisible" ref="searchOverlay" @toggleSearch="toggleSearch" @search="search"></SearchOverlay>

    <DisclaimerModal v-if="showDisclaimer" @closeDisclaimer="closeDisclaimer"></DisclaimerModal>

  </div>

</template>


<script>

import BackButton from "./components/BackButton.vue"
import LeftNav from './components/LeftNav.vue'
import RightNav from './components/RightNav.vue'
import MobileNav from "./components/MobileNav.vue"
import FooterView from "./components/FooterView.vue"
import MiniFooterView from "./components/MiniFooterView.vue"
import MiniMediaPlayer from "./components/MiniMediaPlayer.vue"
import SearchOverlay from "./components/SearchOverlay.vue"
import DisclaimerModal from "./components/DisclaimerModal.vue"
import axios from "axios";


export default {
  name: 'App',
  components: {
    LeftNav,
    RightNav,
    MobileNav,
    FooterView,
    MiniFooterView,
    BackButton,
    MiniMediaPlayer,
    SearchOverlay,
    DisclaimerModal
  },
  methods:
  {
    passWheel(event)
    {
      if (event.target.id == "mainRow" && (this.$route.name == "intro" || this.$route.name == "intro-animation"))
      {
        console.log("Wheel: " + event.target.id);
        console.log(event.wheelDelta)
        this.$refs.mainView.scrollTop -= event.wheelDelta;
      }
    },
    back()
    {
      console.log("Back");
      this.myTransition = "slide-fade-reverse";
      this.$router.back();

      setTimeout(() => {
        this.myTransition = "slide-fade";
      }, 900);

      return false;
    },
    home()
    {
      this.myTransition = "slide-fade-reverse";
      this.$router.push('/home/');
      setTimeout(() => {
        this.myTransition = "slide-fade";
      }, 900);
    },
    nextIntro()
    {
      //console.log("Next intro - " + this.$route.name);

      if (this.$route.name == 'intro')
      {
        //this.$router.push('/intro/');
        this.$router.push('/intro-animation/');
      }
      /*else if (this.$route.name == 'intro-text-view')
      {
        
      }*/
      else if (this.$route.name == "intro-animation")
      {
        this.$router.push('/home/');
      }
    },
    toggleSearch()
    {
      this.searchVisible = !this.searchVisible;
    },
    search(searchTerm)
    {
      this.$store.state.searchTerm = searchTerm;
      this.$router.push('/search/');
    },
    showMiniPlayer()
    {
      this.$refs.miniMediaPlayer.showPlayer();
    },
    hideMiniPlayer()
    {
      this.$refs.miniMediaPlayer.hidePlayer();
    },
    setApprovalCode(approvalCode)
    {
      this.$refs.footerView.setApprovalCode(approvalCode);
    },
    setAudioFile(audioToPlay)
    {
      console.log("Set audio file: " + audioToPlay);
      if (this.audioFile != audioToPlay)
      {
        this.audioFile = audioToPlay;  
        this.$refs.audioPlayer.load();
      }
      
      //this.$store.state.audioIsPlaying = true;
      //this.$refs.audioPlayer.play().catch(err => this.setAudioOff(err));

      this.audioTitle = this.$store.state.audioTrackName;
      console.log("Audio title: " + this.audioTitle);
      this.currentPodcastIndex = this.$store.getters.getPodcastIndexFromURL(audioToPlay);
      console.log("Audio index: " + this.currentPodcastIndex);
      //console.log("Current audio index: " + this.currentPodcastIndex);
    },
    setAudioOff()
    {
      this.$store.state.audioIsPlaying = false;
    },
    playAudio()
    {
      console.log("*** Play audio!");
      this.$refs.audioPlayer.play();
      this.$store.state.audioIsPlaying = true;
    },
    pauseAudio()
    {
      this.$refs.audioPlayer.pause();
      this.$store.state.audioIsPlaying = false;
    },
    closeDisclaimer()
    {
      this.showDisclaimer = false;
    },
    setAudioPosition(position)
    {
      this.$refs.audioPlayer.currentTime = position;
      this.$store.state.audioPosition = position;
      this.playAudio();
    },
    updatePlayer()
    {
      this.$store.state.audioDuration = this.$refs.audioPlayer.duration
      this.$store.state.audioPosition = this.$refs.audioPlayer.currentTime;
      
      //console.log("Update player: " + this.$store.state.audioPosition);
      this.audioTitle = this.$store.state.audioTrackName;
    },
    back10()
    {
      this.$refs.audioPlayer.currentTime = this.$refs.audioPlayer.currentTime - 10;
      this.$refs.audioPlayer.play();
      this.$store.state.audioIsPlaying = true;
    },
    forward10()
    {
      this.$refs.audioPlayer.currentTime = this.$refs.audioPlayer.currentTime + 10;
      this.$refs.audioPlayer.play();
      this.$store.state.audioIsPlaying = true;
    },
    nextTrack()
    {
      //console.log("Next track (Total podscasts: " + this.$store.state.podcasts.length + ")");

      if (this.currentPodcastIndex >= (this.$store.state.podcasts.length) - 1)
      {
        //console.log("Loop back to start");
        this.playAudioFromIndex(0);
      }
      else
      {
        //console.log("Play next track");
        this.playAudioFromIndex(this.currentPodcastIndex + 1);
      }
    },
    previousTrack()
    {
      //console.log("Previous track");

      if (this.currentPodcastIndex <= 0)
      {
        this.playAudioFromIndex(this.$store.state.podcasts.length - 1);
      }
      else
      {
        this.playAudioFromIndex(this.currentPodcastIndex - 1);
      }
    },
    playAudioFromIndex(newIndex)
    {
      console.log("Playing audio at index: " + newIndex);

      // The next podcast in sequence
      var newPodcast = this.$store.state.podcasts[newIndex];

      // Check if we are inside the main media player?
      //console.log("Current route index is: " + this.$route.name);
      if (this.$route.name == "media-player")
      {
        this.$router.push('/media-player' + newPodcast.path[0].alias);
      }
      else
      {
        var newAudioFile = newPodcast.field_mp3[0].url;
        //console.log("New track is: " + newPodcast.field_mp3[0].url);
        this.$store.state.audioTrackName = newPodcast.title[0].value;
        this.setAudioFile(newAudioFile);
      }
    },
    audioEnded()
    {
      //console.log("Audio ended");
      this.nextTrack();
    },
    changeSpeed()
    {
      if (this.$store.state.audioPlaybackSpeed == 1)
      {
        this.$store.state.audioPlaybackSpeed = 1.5;
      }
      else if (this.$store.state.audioPlaybackSpeed == 1.5)
      {
        this.$store.state.audioPlaybackSpeed = 2;
      }
      else
      {
        this.$store.state.audioPlaybackSpeed = 1;
      }

      this.$refs.audioPlayer.playbackRate = this.$store.state.audioPlaybackSpeed;
    }
  },
  data() 
  {
    return {
      myTransition: "slide-fade",
      isHome: true,
      panelMembers: [],
      audioFile: "",
      audioTitle: "Test",
      searchVisible: false,
      isIntro: true,
      currentPodcastIndex: 0,
      showDisclaimer: false
    }
  },
  async created()
  { 
    try 
    {
      const res = await axios.get("https://exchangepod.devarea.io/backend/podcasts");
      this.$store.state.podcasts = res.data;
      console.log(this.$store.state.podcasts);

      /*this.$store.state.podcasts.forEach( element => {
        console.log(element.title[0].value + " / " + element.nid[0].value + " / Parent: " + element.field_expert[0].target_id)
      });*/

      this.$store.getters.getPodcastPlaylist();
    }
    catch (error) 
    {
      console.log(error);
    }

    try 
    {
      const res = await axios.get("https://exchangepod.devarea.io/backend/experts");
      this.$store.state.panelMembers = res.data;

      console.log(this.$store.state.panelMembers);
      /*
      console.log(this.$store.state.panelMembers[0].path[0].alias);
      console.log(this.$store.state.panelMembers[0].field_profile_picture[0].url);
      console.log(this.$store.state.panelMembers[0].title[0].value);
      console.log(this.$store.state.panelMembers[0].body[0].value);*/

    } 
    catch (error) 
    {
      console.log(error);
    }

    try 
    {
      const res = await axios.get("https://exchangepod.devarea.io/backend/topics");
      this.$store.state.topics = res.data;
      console.log(this.$store.state.topics);

      //this.$store.state.topics.forEach( element => {
      //  console.log(element.name[0].value + " / " + element.tid[0].value + " / Parent: " + element.parent[0].target_id)
      //});
    }
    catch (error) 
    {
      console.log(error);
    }

    
  },
  mounted()
  {
    if (localStorage.getItem("disclaimer") != "1")
    {
        this.showDisclaimer = true;
    }
  },
  watch: 
  {
    $route(to, from)
    {
      console.log("To: " + to.name + " // From: " + from.name);

      // Toggle the mini player if audio is playing
      if ((from.name == 'media-player') && this.$store.state.audioIsPlaying)
      {
        this.showMiniPlayer();
      }

      if (to.name == "intro" || to.name == "intro-text-view" || to.name == "intro-animation") 
      {
        this.isIntro = true;
      }
      else
      {
        this.isIntro = false;
      }

      if (to.name == "home")
      {
        this.isHome = true;
      }
      else
      {
        this.isHome = false;
      }
      
    }
  }
}
</script>

<style lang="scss">

html, body
{
  overflow-y: hidden;
  overflow-x: hidden;
}

.backBtn
{
  transition: opacity 0.3s;
}

#mainRow
{
  /*background-color: red;*/
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 7px;
  background: #000000;
  border-radius: 5px;
  background-image: linear-gradient(#0077ED, #0077ED);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #0077ED;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #0077ED;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #0077ED;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #0077ED;
}

input[type="range"]::-moz-range-thumb:hover {
  background: #0077ED;
}

input[type="range"]::-ms-thumb:hover {
  background: #0077ED;
}

/* Input Track */
input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type=range]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}



.hidden
{
  opacity: 0;
  transition: opacity 0.3s;
}

.mainView
{
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.mainView::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.horizontalContainer
{
  overflow-x: scroll;
  display: flex;
}

@media (min-width: 1280px)
{
  .container-fluid
  {
    max-width: 1280px;
  }
}

@media (min-width: 992px)
{
  #mobileNav, #slideOutMobileMenu
  {
    display: none;
  }
}

.container-fluid
{
  background-color: white;
  height: 100%;
}

#mainRow
{
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #cecece;
  height: 100vh;
  overflow: hidden;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.clickable
{
  cursor: pointer;
}

.fade-enter-active {
  transition: all 0.5s ease-out;
}

.fade-enter-from
{
    opacity: 0;
}

.fade-leave-active {
  transition: all 0.5s ease-in;
}
.fade-leave-to {
  opacity: 0;
}



.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-enter-from
{
    transform: translateX(1024px);
    opacity: 0;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-in;
}
.slide-fade-leave-to {
  transform: translateX(-1024px);
  opacity: 0;
}

.slide-fade-reverse-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-reverse-enter-from
{
    transform: translateX(-1024px);
    opacity: 0;
}

.slide-fade-reverse-leave-active {
  transition: all 0.5s ease-in;
}
.slide-fade-reverse-leave-to {
  transform: translateX(1024px);
  opacity: 0;
}

.disclaimer
{
  font-size: 10px;
  font-family: "Poppins";
  margin-top: 20px;
}

.smallDisclaimer
{
  color:#A7A7A7;
  font-size: 8px;
  font-family: "Poppins";
}

</style>
