<template>

  <div class="mobileNavWrapper">

    <div class="row" id="mobileNav">
        <div class="col-12">
            <!--Navbar-->
            <nav class="navbar navbar-dark  indigo darken-2 mt-3">

            
                <div>
                    <img src="../assets/img/left-nav-pod-logo.png" alt="The Exchange Pod Logo" style="max-width: 80px; cursor: pointer;" @click="home()" />
                </div>

                <div class="endItems">
                    <div class="mobileSearch">
                        <img @click="toggleSearch()" src="../assets/img/search.png" alt="Search Icon" class="img-fluid clickable" style="max-width: 40px;">
                    </div>
                    <!-- Collapse button -->
                    <button class="navbar-toggler third-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent22"
                        aria-controls="navbarSupportedContent22" aria-expanded="false" aria-label="Toggle navigation" @click="toggleNav">
                        <div class="animated-icon" :class="{open: open}"><span></span><span></span><span></span></div>
                    </button>

                    <!-- Collapsible content -->
                    <div class="collapse navbar-collapse" id="navbarSupportedContent22">

                        <!-- Links -->
                        <ul class="navbar-nav mr-auto">
                        <li class="nav-item active">
                            <a class="nav-link">Home <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Features</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Pricing</a>
                        </li>
                        </ul>
                        <!-- Links -->

                    </div>
                    <!-- Collapsible content -->
                </div>

            </nav>
        </div>
    </div>

    <div id="slideOutMobileMenu" :class="{hidden: hidden}">
        <MainMenu @toggleNav="toggleNav" @home="home"></MainMenu>
    </div>
  </div>
</template>


<script>
import MainMenu from "./MainMenu.vue"

export default {
  name: 'MobileNav',
  components: {
    MainMenu
  },
  methods:
  {
    toggleNav()
    {
        this.open = !this.open;
        this.hidden = !this.hidden;
    },
    toggleSearch()
    {
      this.open = false;
      this.hidden = true;
      this.$emit('toggleSearch');
    },
    home()
    {
      this.$emit('home');
    }
  },
  data()
  {
    return {
      open: false,
      hidden: true
    }
  }

}
</script>

<style scoped lang="scss">

#slideOutMobileMenu
{
    width: 100%;
    position: absolute;
    z-index: 3;
    left: 0px;
    transition: left 0.4s, opacity 0.5s;
    opacity: 1;
    height: 100%;
    background-color: white;
}
#slideOutMobileMenu.hidden
{
    left: 1200px;
    transition: left 0.4s, opacity 0.5s;
    opacity: 0;
}
nav
{
    padding: unset;
}

.mobileSearch
{
    margin-right: 10px;
}

.endItems
{
    display: flex;
}

.animated-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.animated-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


.animated-icon span {
  background: #000000;
}

.animated-icon span:nth-child(1) {
 top: 0px;
}

.animated-icon span:nth-child(2) {
  top: 10px;
}

.animated-icon span:nth-child(3) {
  top: 20px;
}

.animated-icon.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.clickable
{
    cursor: pointer;
}

</style>