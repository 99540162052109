<template>
    

    <div class="likedPosts mt-3">
        <div class="row">
            <div class="col-12">
                <h1>My liked posts</h1>

                <p class="noContent mt-3" v-if="likedPodcasts.length == 0">You have not liked any posts yet</p>
            </div>
        </div>
        <div class="row" v-if="likedPodcasts.length > 0">
            <div class="col-12">
                <div class="likedPostsContainer py-2">
                    <div class="d-flex flex-row flex-nowrap">

                        <template v-for="item in likedPodcasts" :key="item.nid[0].value">
                            <PodcastCard
                            :id="item.nid[0].value" 
                            :date="getFormattedDate(item.created[0].value)"
                            :category="this.$store.getters.getTopicNameFromId(item.field_topic[0].target_id)"
                            :presenter="this.$store.getters.getExpertNameFromId(item.field_expert[0].target_id)"
                            :profilePhoto="this.$store.getters.getExpertPhotoFromId(item.field_expert[0].target_id)"
                            :body="item.field_description[0].value"
                            :path="item.path[0].alias"
                            :presenterPath="this.$store.getters.getExpertForPodcastId(item.nid[0].value)?.path[0].alias"
                            :duration="item.field_duration[0].value"
                            :likeCount="item.field_total_likes[0].value"
                            :minWidth="likedPodcasts.length == 1"></PodcastCard>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">

h1
{
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    color: black;
    text-align: left;
}

.noContent
{
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: black;
}

.likedPostsContainer
{
    overflow-x: scroll;
    display: flex;
}

.likedPosts .card
{
    min-width: 90%;
    margin-right: 20px;
}

@media screen and (max-width: 480px)
{
    .likedPosts .card
    {
        max-width: 90%;
        /*min-width: unset !important;*/
        min-width: 320px;
    }
}

</style>

<script>
import PodcastCard from '../components/PodcastCard.vue'
import utils from '../utils.js';

export default {
    name: 'LikedPosts',
    mixins: [utils],
    components: {
        PodcastCard
    },
    computed:
    {
        likedPodcasts()
        {
            return this.$store.state.podcasts.filter((podcast) =>
            {
                return this.$store.state.likedPodcasts.includes(podcast.nid[0].value);
            });
        }
    }
}
</script>