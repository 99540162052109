<template>

    <div class="askQuestion mt-2">
        
        <div class="row" v-if="hideIntroText">
            <div class="col-12">
                <h2>Suggest a topic</h2>
                <p>And our experts will aim to develop content for you*</p>
            </div>
        </div>

    <form class="needs-validation" :class="{'was-validated': wasValidated}" :id="questionFormId" ref="questionForm" novalidate @submit.prevent="onSubmit">
        <div class="questionBox" v-if="!formSubmitted">
            <!--<form>-->
                <div class="row mt-2">
                    <div class="col-12">
                        <textarea @keyup="isFormValid()" @change="isFormValid()" placeholder="Type your suggestion**" ref="questionText" id="questionText" name="questionText" class="form-control" required autocomplete="off"></textarea>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <input @keyup="isFormValid()" @change="isFormValid()" type="text" ref="jobTitle" id="jobTitle" name="jobTitle" class="form-control" placeholder="Job title**" required autocomplete="off">
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <input @keyup="isFormValid()" @change="isFormValid()" type="text" ref="emailAddress" id="emailAddress" name="emailAddress" class="form-control" placeholder="Work email address**" required autocomplete="off">
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <input @keyup="isFormValid()" @change="isFormValid()" type="text" ref="postcode" id="postcode" name="postcode" class="form-control" placeholder="Work postcode**" required autocomplete="off">
                    </div>
                </div>

                <div class="row form-check mt-2">
                    <div class="col-12">
                        <!--<input @change="isFormValid()" type="checkbox" value="" class="form-check-input" name="exampleCheck1" required>-->
                        <label class="form-check-label" for="exampleCheck1">Your information will be used to put you in contact with Novartis and handled in accordance with our <a style="text-decoration: underline; color: #0077ED; cursor: pointer;" @click="showPrivacy()">Privacy Notice</a></label>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-10 gcap">
                        <vue-recaptcha ref="recaptcha" sitekey="6LegJ-ogAAAAAII9h7UNuzOorED0_LRaRUbuywk_"
                        @verify="verifyMethod"
                        @expired="expiredMethod" />
                    </div>
                </div>
                
                <div class="row mt-2">
                    <div class="col-12">
                        <p class="bottomText">For medical information, please contact our Medical Information team by <a href="mailto:medinfo.uk@novartis.com">email</a></p>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <p class="bottomText">*While we strive to create new content, it might not always be immediately possible. We appreciate your patience and understanding and encourage you to continue submitting your topic suggestions</p>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <p class="bottomText">Fields marked ** are mandatory</p>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <button :class="{isValid: formValid}" @click="submitForm()">Submit</button>
                    </div>
                </div>

            <!--</form>-->
        </div>

        <div class="questionBox" v-else>
            <div class="row">
                <div class="col-12">
                    <h1>Thank you for your suggestion</h1>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <p>We will aim to create new content addressing your topic suggestion, and others received from the community, as soon as possible</p>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <button class="isValid" @click="anotherQuestion()">Submit another topic</button>
                </div>
            </div>
        </div>
    </form>

    </div>

</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import axios from "axios";

export default {
    name: "AskQuestion",
    components:
    {
        VueRecaptcha
    },
    data()
    {
        return {
            recaptca_response: "",
            wasValidated: false,
            formValid: false,
            formSubmitted: false
        }
    },
    props:
    {
        questionFormId: String,
        hideIntroText: Boolean
    },
    methods:
    {
        showPrivacy()
        {
            this.$router.push('/privacy-notice/');
        },
        anotherQuestion()
        {
            this.formSubmitted = false;
        },
        isFormValid()
        {
            this.wasValidated = true;
            try
            {
                //console.log("Validating form with id: " + this.questionFormId);
                if (document.getElementById(this.questionFormId).checkValidity() && this.recaptca_response.length > 1)
                {
                    this.formValid = true;
                    //console.log("Valid");
                    return "";
                }
                else
                {
                    this.formValid = false;
                    //console.log("Invalid");
                    return "formInvalid";
                }
            }
            catch (err)
            {
                //console.log("Error validating form");
            }
        },
        onSubmit() 
        {
            //console.log("Submit...")

            // Form is valid 
            if (document.getElementById("" + this.questionFormId).checkValidity() && this.recaptca_response.length > 1)
            {
                //console.log("Valid form");
                this.formValid = true;
            }
            else
            {
                //console.log("Invalid form");
                this.formValid = false;
            }
            //console.log(JSON.stringify(form_data));
        },
        submitForm()
        {
            //this.$refs.recaptcha.execute();

            const self = this;
            self.status = "submitting";

            if (!this.formValid)
            {
                return;
            }
            
            axios.post("https://exchangepod.devarea.io/submit-question.php", 
            {
                question: this.$refs.questionText.value,
                email: this.$refs.emailAddress.value,
                job_title: this.$refs.jobTitle.value,
                postcode: this.$refs.postcode.value,
                recaptchaToken: this.recaptca_response
            }).then(response =>
            {
                console.log(response.data);

                self.sucessfulServerResponse = response.data.message;

                if (response.data == "1")
                {
                    this.formSubmitted = true;
                }
            }).catch(function (err)
            {
                self.serverError = getErrorMessage(err);

                //helper to get a displayable message to the user
                function getErrorMessage(err) 
                {
                    let responseBody;
                    responseBody = err.response;
                    if (!responseBody) 
                    {
                        responseBody = err;
                    }
                    else 
                    {
                        responseBody = err.response.data || responseBody;
                    }

                    return responseBody.message || JSON.stringify(responseBody);
                }

            }).then(() => 
            {
                self.status = "";
            });

        },
        verifyMethod(response)
        {
            //console.log("Verify: " + response);
            this.recaptca_response = response;

            this.isFormValid();
        },
        expiredMethod()
        {
            this.$refs.recaptcha.reset();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";



.gcap {
    transform:scale(0.65);
    transform-origin:0 0;
}

.questionBox
{
    border: 2px solid #FF8800;
    border-radius: $custom-border-radius;
    background-color: white;
    padding: 1px;
    padding: 0.5rem 1rem;
}

.questionBox h1
{
    text-align: center;
    font-size: 14px;
    font-weight: 700;
}
.questionBox p
{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

textarea
{
    height: 100px;
}
textarea, input[type=text]
{
    border: 1px solid #D8D8D8;
    background-color: #F5F7F9;
    border-radius: $custom-border-radius;
    font-family: "Poppins";
    font-size: 12px;
}

h2
{
    text-align: left;
    color: black;
    font-size: 16px;   
    font-weight: 700;
    font-family: "Poppins";
}

p
{
    font-family: "Poppins";
    font-size: 14px;
    text-align: left;
    font-weight: 400;
}
label
{
    font-size: 10px;
    font-family: "Poppins";
    text-align: left;
    font-weight: 400;
}
.bottomText
{
    font-size: 10px !important;
    font-weight: 400 !important;
    color: #747474;
}

button
{
    font-family: "Poppins";
    color: #0077ED;
    border: 2px solid #0077ED;
    border-radius: $custom-border-radius;
    background-color: white;
    font-size: 14px;
    font-weight: 600;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    opacity: 0.3;
}

.isValid
{
    opacity: 1.0;
}

@media screen and (min-width: 1132px)
{
    .gcap {
        transform:scale(0.75);
    }
}

@media screen and (min-width: 1200px)
{
    .gcap {
        transform:scale(0.85);
    }
}

@media screen and (max-width: 1131px)
{
    .gcap {
        transform:scale(0.65);
    }
}

@media screen and (max-width: 767px)
{
    .gcap {
        transform: unset;
    }
}

</style>