import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    //component: HomeView
    component: HomeView
  },
  {
    path: '/',
    name: "intro",
    component: () => import(/* webpackChunkName: "intro-view" */ '../views/IntroView.vue')
  },
  {
    path: '/intro/',
    name: "intro-text-view",
    component: () => import(/* webpackChunkName: "intro-text-view" */ '../views/IntroTextView.vue')
  },
  {
    path: '/intro-animation/',
    name: "intro-animation",
    component: () => import(/* webpackChunkName: "intro-animation" */ '../views/IntroAnimation.vue')
  },
  {
    path: '/category/:id',
    name: 'category',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "category" */ '../views/CategoryView.vue')
  },
  {
    path: '/media-player/:id',
    name: 'media-player',
    component: () => import(/* webpackChunkName: "media-player-view" */ '../views/MediaPlayerView.vue')
  },
  {
    path: '/expert-panel',
    name: 'expert-panel',
    component: () => import(/* webpackChunkName: "expert-panel" */ '../views/ExpertPanelView.vue')
  },
  {
    path: '/view-profile/:id',
    name: 'view-profile',
    component: () => import(/* webpackChunkName: "view-profile" */ '../views/ViewProfile.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUsView.vue')
  },
  {
    path: '/privacy-notice',
    name: 'privacy-notice',
    component: () => import(/* webpackChunkName: "privacy-notice" */ '../views/PrivacyNoticeView.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/TermsOfUseView.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/SearchView.vue')
  },
  /*{
    path: '/test',
    name: 'test',
    component: () => import( '../views/TestView.vue')
  }*/ 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    console.log("From: " + from);
    console.log("savedPosition: " + savedPosition);


    document.getElementById('mainView').scrollTop = 0;

    /*if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }*/
  }
})

export default router
