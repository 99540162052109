import { createApp } from 'vue'
import { createStore } from "vuex";

import App from './App.vue'
import router from './router'
import axios from "axios";

const store = createStore({
    state () {
      return {
        count: 1,
        panelMembers: [],
        topics: [],
        podcasts: [],
        audioIsPlaying: false,
        audioDuration: Number,
        audioPosition: Number,
        audioPlaybackSpeed: 1,
        audioTrackName: "",
        likedPodcasts: [],
        searchTerm: ""
      }
    },
    mutations: {
      increment (state) {
        state.count++
      },
      likePodcast(state, podcastId)
      {
        // Add like
        if (!state.likedPodcasts.includes(podcastId))
        {
            state.likedPodcasts.push(podcastId);

            // Update server
            try 
            {
                axios.get("https://exchangepod.devarea.io/backend/update/likes/" + podcastId);
            }
            catch (error) 
            {
                console.log(error);
            }
        }
        // Remove Like
        else
        {
            var index = state.likedPodcasts.indexOf(podcastId);
            if (index !== -1) 
            {
                state.likedPodcasts.splice(index, 1);
            }

            // Update server
            try 
            {
                axios.get("https://exchangepod.devarea.io/backend/update/unlike/" + podcastId);
            }
            catch (error) 
            {
                console.log(error);
            }
        }
      },
      initialiseStorage(state)
      {
        //console.log("Loading local storage");
        if (localStorage.getItem('exp_pod_likes'))
        {
            //console.log("Loaded local storage");
            var oldStorage = JSON.parse(localStorage.getItem('exp_pod_likes'));
            //console.log("Old storage: " + oldStorage);
            state.likedPodcasts = oldStorage;
        }
      }
    },
    getters:
    {
        getPodcastPlaylist: (state) =>
        {
            for (var a = 0; a < state.podcasts.length; a++)
            {
                //console.log("Podcast title: " + state.podcasts[a].title[0].value);
            }
        },
        doesLikePodcast: (state) => (id) =>
        {
            //console.log("Size of likes: " + state.likedPodcasts.length);
            for (var a = 0; a < state.likedPodcasts.length; a++)
            {
                if (state.likedPodcasts[a] == id)
                {
                    //console.log("User has liked podcast: " + id);
                    return true;
                }
            }

            //console.log("User has hasn't liked podcast: " + id);
            return false;
        },
        getPodcastIndexFromURL: (state) => (audioURL) =>
        {
            for (var a = 0; a < state.podcasts.length; a++)
            {
                if (state.podcasts[a].field_mp3[0].url == audioURL)
                {
                    return a;
                }
            }
            return 0;
        },
        getSubTopicsForId: (state) => (id) =>
        {
            var subtopics = [];
            for (var a = 0; a < state.topics.length; a++)
            {
                if (state.topics[a].parent[0].target_id == id)
                {
                    subtopics.push(state.topics[a].tid[0].value);
                }
            }

            return subtopics;
        },
        getTopicNameFromId: (state) => (id) =>
        {
            for (var a = 0; a < state.topics.length; a++)
            {
                if (state.topics[a].tid[0].value == id)
                {
                    return state.topics[a].name[0].value;
                }
            }
        },
        getExpertNameFromId: (state) => (id) =>
        {
            for (var a = 0; a < state.panelMembers.length; a++)
            {
                if (state.panelMembers[a].nid[0].value == id)
                {
                    return state.panelMembers[a].title[0].value;
                }
            }
        },
        getExpertPhotoFromId: (state) => (id) =>
        {
            for (var a = 0; a < state.panelMembers.length; a++)
            {
                if (state.panelMembers[a].nid[0].value == id)
                {
                    return state.panelMembers[a].field_profile_picture[0].url;
                }
            }
        },
        getExpertBodyFromId: (state) => (id) =>
        {
            for (var a = 0; a < state.panelMembers.length; a++)
            {
                if (state.panelMembers[a].nid[0].value == id)
                {
                    return state.panelMembers[a].body[0].value;
                }
            }
        },
        getExpertIdFromAlias: (state) => (alias) =>
        {
            for (var a = 0; a < state.panelMembers.length; a++)
            {
                if (state.panelMembers[a].path[0].alias == "/" + alias)
                {
                    return state.panelMembers[a].nid[0].value;
                }
            }
        },
        getPodcastIdFromAlias: (state) => (alias) =>
        {
            for (var a = 0; a < state.podcasts.length; a++)
            {
                if (state.podcasts[a].path[0].alias == "/" + alias)
                {
                    return state.podcasts[a].nid[0].value;
                }
            }
        },
        getPodcastFromAlias: (state) => (alias) =>
        {
            //console.log("Getting podcast for alias:" + alias);
            //console.log("Podcast array size: " + state.podcasts.length);
            for (var a = 0; a < state.podcasts.length; a++)
            {
                if (state.podcasts[a].path[0].alias == "/" + alias)
                {
                    return state.podcasts[a];
                }
            }
        },
        getExpertForPodcastId: (state) => (id) =>
        {
            for (var a = 0; a < state.podcasts.length; a++)
            {
                if (state.podcasts[a].nid[0].value == id)
                {
                    for (var b = 0; b < state.panelMembers.length; b++)
                    {
                        if (state.panelMembers[b].nid[0].value == state.podcasts[a].field_expert[0].target_id)
                        {
                            return state.panelMembers[b];
                        }
                    }
                }
            }
        },
        podcastsReady: (state) =>
        {
            //console.log("Podcasts length: " + state.podcasts.length);
            if (state.podcasts.length <= 0)
            {
                return false;
            }
            return true;
        }
    }
  });


  store.subscribe((mutation, state) => {
        // Store the state object as a JSON string
        localStorage.setItem('exp_pod_likes', JSON.stringify(state.likedPodcasts));
    });

//import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'


createApp({
    extends: App,
    beforeCreate()
    {
        this.$store.commit('initialiseStorage');
    }
})
.use(router)
.use(store)
.use(Vue3Lottie)
.mount('#app')
