<template>
    

    <div class="row mb-3">
        <div class="col-4 px-0">
            <button class="showAllBtn" @click="selectTopic(0);">Show all</button>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12 ">
            <div class="row align-items-center">
                
                <div class="col-3 clickable" @click="selectTopic(1);" :class="{selected: (currentTopic == 1)}">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-12 px-0">
                            <img src="../assets/img/icon1-blue.png" id="icon1a" v-if="icon1Visible" @mouseover="toggle(1)" class="img-fluid" />
                            <img src="../assets/img/icon1-white.png" id="icon1b" v-if="!icon1Visible" @mouseleave="toggleOff(1)" class="img-fluid" />
                        </div>
                        <div class="col-lg-8 col-12 px-1">
                            <p>Monitoring patients</p>
                        </div>
                    </div>
                </div>

                <div class="col-3 clickable" @click="selectTopic(2);" :class="{selected: (currentTopic == 2)}">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-12 px-0">
                            <img src="../assets/img/icon2-blue.png" id="icon2a" v-if="icon2Visible" @mouseover="toggle(2)" class="img-fluid" />
                            <img src="../assets/img/icon2-white.png" id="icon2b" v-if="!icon2Visible" @mouseleave="toggleOff(2)" class="img-fluid" />
                        </div>
                        <div class="col-lg-8 col-12 px-1 ">
                            <p>Managing<br />SCD</p>
                        </div>
                    </div>
                </div>

                <div class="col-3 clickable" @click="selectTopic(3);" :class="{selected: (currentTopic == 3)}">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-12 px-0">
                            <img src="../assets/img/icon3-blue.png" id="icon3a" v-if="icon3Visible" @mouseover="toggle(3)" class="img-fluid" />
                            <img src="../assets/img/icon3-white.png" id="icon3b" v-if="!icon3Visible" @mouseleave="toggleOff(3)" class="img-fluid" />
                        </div>
                        <div class="col-lg-8 col-12 px-1 ">
                            <p>Preparation &amp;&nbsp;practice</p>
                        </div>
                    </div>
                </div>

                <div class="col-3 clickable" @click="selectTopic(4);" :class="{selected: (currentTopic == 4)}">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-12 px-0">
                            <img src="../assets/img/icon4-blue.png" id="icon4a" v-if="icon4Visible" @mouseover="toggle(4)" class="img-fluid" />
                            <img src="../assets/img/icon4-white.png" id="icon4b" v-if="!icon4Visible" @mouseleave="toggleOff(4)" class="img-fluid" />
                        </div>
                        <div class="col-lg-8 col-12 px-1 ">
                            <p>ADAKVEO&reg;▼ <span class="inlineSpan">(crizanlizumab)</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: 'TopicSelector',
    emits: ["selectTopic"],
    methods:
    {
        selectTopic(index)
        {
            this.icon1Visible = true;
            this.icon2Visible = true;
            this.icon3Visible = true;
            this.icon4Visible = true;

            this.currentTopic = index;
            this.$emit('selectTopic', this.currentTopic);

            if (index == 1)
            {
                this.icon1Visible = false;
            }
            if (index == 2)
            {
                this.icon2Visible = false;
            }
            if (index == 3)
            {
                this.icon3Visible = false;
            }
            if (index == 4)
            {
                this.icon4Visible = false;
            }
        },
        toggle(element)
        {
            console.log("On " + element)
            if (element == "1")
            {
                this.icon1Visible = false;
            }
            if (element == "2")
            {
                this.icon2Visible = false;
            }
            if (element == "3")
            {
                this.icon3Visible = false;
            }
            if (element == "4")
            {
                this.icon4Visible = false;
            }
        },
        toggleOff(element)
        {
            if (this.currentTopic == element)
            {
                return;
            }
            console.log("Off " + element)
            if (element == "1")
            {
                this.icon1Visible = true;
            }
            if (element == "2")
            {
                this.icon2Visible = true;
            }
            if (element == "3")
            {
                this.icon3Visible = true;
            }
            if (element == "4")
            {
                this.icon4Visible = true;
            }
        }
    },
    data()
    {
        return {
            currentTopic: 0,
            icon1Visible: true,
            icon2Visible: true,
            icon3Visible: true,
            icon4Visible: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";


.inlineSpan
{
    font-size: 8px; 
    color: black;
    position: unset;
}
.showAllBtn
{
    border: 2px solid #0077ED;
    color: #0077ED;
    background-color: white;
    width: 90%;
    border-radius: $custom-border-radius;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 600;
    float: left;
    transition: background-color 0.3s, color 0.3s;
}
.showAllBtn:hover
{
    background-color: #0A77ED;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}

p
{
    font-family: "Poppins";
    font-size: 12px;
    color: black;
    text-align: left;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    transition: font-weight 0.3s;
}

.selected p
{
    font-weight: 600;
    transition: font-weight 0.3s;
    color: #0A77ED;
}

.off
{
    display: block;
}

.on
{
    display: none;
}

.selected .off
{
    display: none;
}
.selected .on
{
    display: block;
}

.clickable:hover p, .clickable:hover span
{
    color: #0A77ED;
}


@media (max-width: 991.98px) 
{
    p
    {
        text-align: center !important;
        margin-top: 6px !important;
    }
}

</style>