<template>
    
    <div class="card col-md-5 col-7 me-2 mb-2">
        <div class="card-header">
            <div class="row">
                <div class="col-12" style="display: flex; flex-direction: column;">
                    <div class="row">
                        <div class="col-12">
                            <p class="align-top" v-html="date"></p>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-8 col-md-6">
                    <img :src="profilePhoto" class="img-fluid" alt="Kofi Anie" />
                </div>
            </div>

            <div class="row mt-3 justify-content-start">
                <div class="col-12 col-md-10">
                    <h2 class="topic" v-html="category"></h2>
                </div>
            </div>

            <div class="row">
                <h1 class="card-title" v-html="presenter"></h1>
                <p class="card-text" v-html="body"></p>
            </div>
        </div>

        <div class="card-footer">
            <button @click="listen()"><i class="bi bi-arrow-right bi-align-center"></i></button>
        </div>
        
    </div>

</template>

<script>
export default {
    name: "VerticalPodcastCard",
    methods:
    {
        listen()
        {
            this.$emit('setAudioFile', this.mp3);
            this.$router.push('/media-player' + this.path);
        }
        
    },
    props:
    {
        date: String,
        profilePhoto: String,
        category: String,
        presenter: String,
        body: String,
        path: String,
        mp3: String
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";



.card
{
    background-image: linear-gradient(180deg, #ffb100, #ff8800);
    border: none;
    border-radius: $custom-border-radius;
}

.card-header, .card-footer
{
    border: none;
    background-color: transparent;
}

.card-header p
{
    color: white;
    font-size: 9px;
    font-weight: 500;
    font-family: "Poppins";
    text-align: left;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.topic
{
    font-family: "Poppins";
    font-size: 10px;
    font-weight: 700;
    color: white;
    background-color: black;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: $custom-border-radius;
    text-align: left;
    /*height: 23px;*/
    align-self: flex-start;
    display: inline-block;
    float: left;
}

.card-body .card-title
{
    text-align: left;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    color: white;
}

.card-body .card-text
{
    text-align: left;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    color: black;
}

.card-footer
{
    text-align: left;
}

.card-footer button
{
    background-color: white;
    border: none;
    border-radius: 60px;
    width: 60px;
    height: 60px;
    transition: background-color 0.3s, color 0.3s;
}

button:hover
{
    background-color: #0A77ED;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}

.card-footer button i
{
    color: #0077ED;
    font-size: 30px;
    top: 2px;
    
    position: relative;
    transition: color 0.3s;
}

.card-footer button:hover i
{
    color: white;
    transition: color 0.3s;
}

@media (max-width: 991.98px) 
{
    .card-footer button i
    {
        /*left: -6px;*/
    }
}

</style>