<template>
    
    <div class="footer mt-4">
        

        <div class="row justify-content-center" v-if="$route.name=='intro'">
            <div class="col-10">
                <p>This project is funded by Novartis Pharmaceuticals Ltd. and is classified as promotional under the Association of British Pharmaceutical Industry (ABPI) Code</p>
            </div>
        </div>



        <div class="row justify-content-center">
            <div class="col-8">
                <img src="../assets/img/novartis-logo.png" alt="Novartis Logo" class="img-fluid" />       
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MiniFooterView",
    components:
    {

    }
}
</script>

<style lang="scss" scoped>
p
{
    color: black;
    font-size: 10px;
    font-family: "Poppins";
    text-align: center;
}

</style>