<template>
    <div class="home view">
        
        <!--<div class="row mt-3">
            <div class="col-12">
                <h1>Home</h1>

                <p>The Exchange Pod has been developed under the direction and funding of Novartis Pharmaceuticals UK Ltd. in collaboration with UK healthcare professionals</p>
            </div>
        </div>-->

        <div class="row mt-3">
            <div class="col-12">
                <h1>Listen</h1>

                <p>Our expert panel discuss all things SCD and respond to your questions</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 px-3">
                <TopicSelector ref="topicSelector" @selectTopic="selectTopic"></TopicSelector>
            </div>
        </div>

        <div class="row mt-3" v-if="getPodcasts.length == 0">
            <div class="col-12">
                <p class="noContent">There is currently no content in this section</p>
            </div>
        </div>

        <div class="row" v-if="getPodcasts.length > 0">
            <div class="col-12">
                <div class="horizontalContainer">
                    
                    <template v-for="item in getPodcasts" :key="item.nid[0].value">
                        <!--:date="getFormattedDate(item.created[0].value)"-->
                        <VerticalPodcastCard 
                        :id="item.nid[0].value" 
                        
                        :category="this.$store.getters.getTopicNameFromId(item.field_topic[0].target_id)"
                        :presenter="this.$store.getters.getExpertNameFromId(item.field_expert[0].target_id)"
                        :profilePhoto="this.$store.getters.getExpertPhotoFromId(item.field_expert[0].target_id)"
                        :body="item.title[0].value"
                        :path="item.path[0].alias"
                        :mp3="item.field_mp3[0].url"

                        @setAudioFile="setAudioFile"
                         >
                        </VerticalPodcastCard>

                    </template>
                </div>
            </div>
        </div>

        <div class="row mt-3 justify-content-center" v-if="getPodcasts.length > 0">
            <div class="col-6">
                <button class="allPostsBtn" @click="showPosts()">{{ currentPostString }}</button>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <h1>Learn</h1>
                <p>Join the conversation and learn from experts in the field</p>
            </div>
        </div>

        <ExpertPanel></ExpertPanel>
        <div class="d-block d-lg-none">
            <AskQuestion questionFormId="mobileHomeQuestion" hideIntroText="false"></AskQuestion>
        </div>
        <LikedPosts></LikedPosts>
    </div>
      
</template>

<script>

import TopicSelector from "../components/TopicSelector.vue"
import VerticalPodcastCard from "../components/VerticalPodcastCard.vue"
import ExpertPanel from "../components/ExpertPanel.vue"
import AskQuestion from "../components/AskQuestion.vue"
import LikedPosts from "../components/LikedPosts.vue"
import utils from '../utils.js';

export default {
    name: "HomeView",
    components: {
        TopicSelector,
        VerticalPodcastCard,
        ExpertPanel,
        AskQuestion,
        LikedPosts
    },
    mixins: [utils],
    mounted()
    {
        
    },
    methods:
    {
        setAudioFile(audioFile)
        {
            this.$emit('setAudioFile', audioFile);
        },
        showPosts()
        {
            switch (this.selectedTopic)
            {
                case 0:
                    this.$router.push('/category/all');
                break;
                case 1:
                    this.$router.push('/category/monitoring');
                break;
                case 2:
                    this.$router.push('/category/managing');
                break;
                case 3:
                    this.$router.push('/category/preparation');
                break;
                case 4:
                    this.$router.push('/category/adkaveo');
                break;
            }
            
        },
        
        selectTopic(index)
        {
            console.log("Selected topic " + index);
            this.selectedTopic = index;

            switch (this.selectedTopic)
            {
                case 0:
                    this.currentPostString = "All posts";
                break;
                case 1:
                    this.currentPostString = "All monitoring patients posts";
                break;
                case 2:
                    this.currentPostString = "All managing SCD posts";
                break;
                case 3:
                    this.currentPostString = "All preparation & practice posts";
                break;
                case 4:
                    this.currentPostString = "All Adakveo posts";
                break;
            }
        },
        
    },
    data()
    {
        return {
            selectedTopic: 0,
            currentPostString: "All posts",
        }
    },
    computed:
    {
        getPodcasts()
        {
            if (this.selectedTopic != 0)
            {
                var sub_topics = [];
                
                if (this.selectedTopic == 1)
                {
                    sub_topics = this.$store.getters.getSubTopicsForId(3);
                }
                if (this.selectedTopic == 2)
                {
                    sub_topics = this.$store.getters.getSubTopicsForId(4);
                }
                if (this.selectedTopic == 3)
                {
                    sub_topics = this.$store.getters.getSubTopicsForId(5);
                }
                if (this.selectedTopic == 4)
                {
                    sub_topics = this.$store.getters.getSubTopicsForId(6);
                }

                return this.$store.state.podcasts.filter((podcast) => 
                {
                    return sub_topics.includes(podcast.field_topic[0].target_id);
                });
            }
                    
                
            
            return this.$store.state.podcasts;
        }
    },
    props:
    {
        
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss";

h1
{
    text-align: left;
    font-size: 16px !important;
    font-family: "Poppins";
    color: black;
    font-weight: 700;
}

p
{
    font-family: "Poppins";
    font-size: 14px;
    color: black;
    text-align: left;
}

h1.smaller, p.smaller
{
    font-size: 12px !important;
}

.noContent
{
    font-weight: 500;
}

.allPostsBtn
{
    border: 2px solid #0077ED;
    border-radius: $custom-border-radius;
    color: #0077ED;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    width: 100%;
    padding: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.allPostsBtn:hover
{
    background-color: #0A77ED;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}
</style>