<template>
    <div class="col-3 d-none d-lg-block" id="leftNav">
        
        <div class="row mt-2 ms-1">
            <div class="col-5">
                <img @click="home()" src="../assets/img/left-nav-pod-logo.png" alt="The Exchange Pod Logo" class="img-fluid" />
            </div>
        </div>

        <MainMenu @home="home"></MainMenu>
    </div>
</template>

<script>
import MainMenu from "./MainMenu.vue"

export default {
    name: 'LeftNav',
    components: 
    {
        MainMenu
    },
    methods:
    {
        home()
        {
            console.log("Left nav clicked home");
            this.$emit('home');
        }
    }
}
</script>

<style lang="scss" scoped>

img
{
    cursor: pointer;
}
</style>