<template>
    <div class="row ms-1 mt-3 mainNav">
        <div class="col-12">
            <h1 @click="home()" :class="{homeActive: (this.$route.name == 'home')}">Home</h1>

            <hr />
            <div class="row align-items-center justify-content-between leftNavItem" :class="{active: (this.$route.params.id == 'monitoring')}" @click="showCategory('monitoring');">
                <div class="col-1">
                    <img src="../assets/img/icon-monitoring.png" alt="Monitoring Icon" class="img-fluid" />
                </div>
                <div class="col-8">
                    <h2>Monitoring Patients</h2>
                </div>
                <div class="col-2">
                    <i class="bi bi-chevron-right"></i>
                </div>
            </div>
            <hr />

            <div class="row align-items-center justify-content-between leftNavItem" :class="{active: (this.$route.params.id == 'managing')}" @click="showCategory('managing');">
                <div class="col-1">
                    <img src="../assets/img/icon-managing.png" alt="Managing Icon" class="img-fluid" />
                </div>
                <div class="col-8">
                    <h2>Managing SCD</h2>
                </div>
                <div class="col-2">
                    <i class="bi bi-chevron-right"></i>
                </div>
            </div>
            <hr />

            <div class="row align-items-center justify-content-between leftNavItem" :class="{active: (this.$route.params.id == 'preparation')}" @click="showCategory('preparation');">
                <div class="col-1">
                    <img src="../assets/img/icon-prep.png" alt="Preparation Icon" class="img-fluid" />
                </div>
                <div class="col-8">
                    <h2>Preparation &amp; practice</h2>
                </div>
                <div class="col-2">
                    <i class="bi bi-chevron-right"></i>
                </div>
            </div>

            <hr />
            <div class="row align-items-center justify-content-between leftNavItem" :class="{active: (this.$route.params.id == 'experience')}" @click="showCategory('experience');">
                <div class="col-1">
                    <img src="../assets/img/icon-adakveo.png" alt="Adakveo Icon" class="img-fluid" />
                </div>
                <div class="col-8">
                    <h2>ADAKVEO®▼ (crizanlizumab)</h2>
                </div>
                <div class="col-2">
                    <i class="bi bi-chevron-right"></i>
                </div>
            </div>

            <hr />

            <div class="row align-items-center">
                <div class="col-12">
                    <button class="expertPanelBtn mt-3" @click="showExpertPanel()">Expert Panel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainMenu",
    components: {
        
    },
    data()
    {
        return {
            
        }
    },
    methods:
    {
        showExpertPanel()
        {
            this.$router.push('/expert-panel/');
            this.$emit('toggleNav');
        },
        showCategory(categoryName)
        {
            this.$router.push('/category/' + categoryName);
            this.$emit('toggleNav');
        },
        home()
        {
            this.$emit('home');
            this.$emit('toggleNav');
        }
    }
}
</script>


<style scoped lang="scss">
@import "../assets/variables.scss";

.mainNav
{
    width: 100%;
    background-color: white;
}

.col-1
{
    padding: 0;
    margin: 0;
}

.col-1 img
{
    margin-left: 7px;
}

#leftNav
{
    border-right: 1px solid #ededed;
    height: 100%;
    cursor: pointer;
}

.leftNavItem
{
    cursor: pointer;
    height: 37px;
}

.leftNavItem:hover h2, .leftNavItem:hover i
{
    transition: color 0.3s;
    color: #0A77ED;
}



h1
{
    text-align: left;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    transition: color 0.3s;
}

.homeActive
{
    color: #0077ED;
    font-weight: 700;
}
h1:hover
{
    color: $blue-color;
    transition: color 0.3s;
}

h2
{
    text-align: left;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-bottom: 0px;
    transition: color 0.3s;
}

hr
{
    background-color: #ff8800;
    height: 1px;
    opacity: 1;
}

.expertPanelBtn
{
    border: 2px solid #0077ED;
    border-radius: $custom-border-radius;
    background-color: white;
    color: #0077ED;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: background-color 0.3s, color 0.3s;
}

.expertPanelBtn:hover
{
    background-color:#0077ED;
    color:white;
    transition: background-color 0.3s, color 0.3s;
}

.leftNavItem.active h2
{
    color: #0A77ED;
    font-weight: 700;
}

</style>