<template>
    
    <div class="disclaimerModal">
        
         

        <div class="row justify-content-center">
            <div class="col-10 col-md-6 modalInner">

                <div class="row justify-content-center centered mb-4 mt-3">
                    <div class="col-8">
                        <img src="../assets/img/left-nav-pod-logo.png" alt="The Exchange Pod Logo" />
                    </div>
                </div>
                
                <p class="large px-md-5">Welcome to the Novartis Exchange Pod where you can find promotional information about Novartis products, therapy areas and professional resources. This site is for UK healthcare professionals (HCPs) and other relevant decision makers* </p>
            
                <p class="small px-md-5">*Other relevant decision makers particularly includes those with an NHS role who could influence in any way the administration, consumption, prescription, purchase, recommendation, sale, supply or use of any medicine but who are not health professionals</p>
            
                <hr />

                <div class="row justify-content-center centered mb-3">
                    <div class="col-12">
                        <button class="firstButton" @click="closeModal();">I am a UK healthcare professional and/ or an NHS professional involved in service or budgetary planning responsibilities <i class="bi bi-arrow-right-circle-fill clickable" style="top: 20%;"></i></button>
                    </div>
                </div>

                <div class="row justify-content-center centered mb-3">
                    <div class="col-12">
                        <a href="https://www.health.novartis.co.uk/public"><button class="secondButton">I am a member of the public <i class="bi bi-arrow-right-circle-fill clickable" style="top: -7%;"></i></button></a>
                    </div>
                </div>

                <div class="row justify-content-center centered">
                    <div class="col-9">
                        <img src="../assets/img/novartis-logo.png" alt="Novartis Logo" class="img-fluid" />       
                    </div>
                </div> 
                
            </div>
        </div>

    </div>

</template>

<script>
export default {
    name: "DisclaimerModal",
    methods:
    {
        closeModal()
        {
            this.$emit('closeDisclaimer');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/variables.scss"; 

    .disclaimerModal
    {
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 2;
        pointer-events: all;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .modalInner
    {
        background-color: white;
        border-radius: 16px;
    }

    button
    {
        border-radius: 40px;
        border: 2px solid #0077ED;
        background-color: white;
        width: 100%;
        color: #0077ED;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        padding-left: 20px;
        position: relative;
        padding-right: 65px;
    }

    button:hover
    {
        background-color: #0077ED;
        color: white;
        transition: color 0.3s, background-color 0.3s;
    }

    button:hover .bi-arrow-right-circle-fill
    {
        color: white;
        transition: color 0.3s;
    }

    #btnContainer
    {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 20;
    }

    hr
    {
        width: 70%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 25px;
    }

    i
    {
        color: #0077ED;
        font-size: 50px;
        margin-top: -20px;
        left: -10px;
        position: relative;
        z-index: 15;
    }
    #mobileSearch
    {
        height: 70px;
        font-family: "Poppins";
        font-size: 22px;
        border-radius: $custom-border-radius;
        border: none;
        padding-left: 35px;
        z-index: 10;
        pointer-events: all;
    }

    .large
    {
        font-family: "Poppins";
        font-size: 16px;
        text-align: center;
        color: black;
    }

    .small
    {
        font-family: "Poppins";
        font-size: 12px;
        text-align: center;
        color: black;
    }

    .bi-arrow-right-circle-fill
    {
        font-size: 50px;
        color: #0077ED;
        transition: color 0.3s;
        position: absolute;
        margin-top: unset;
        left: unset;
        right: 11px;
    }
    
    .firstButton
    {
        min-height: 130px;
    }

    .secondButton
    {
        min-height: 70px;
    }

    @media screen and (min-width: 769px) 
    {
        .firstButton
        {
            min-height: 80px;
            width: 75%;
        }

        .firstButton i
        {
            margin-top: -12px;
        }

        .secondButton
        {
            min-height: 70px;
            width: 55%;
        }
    }

</style>