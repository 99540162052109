<template>
    <div class="row mb-3 mt-3">
        <div class="col-12">
            <a class="backBtn" @click="back()"><i class="bi bi-arrow-left"></i>&nbsp;Back</a>
        </div>
    </div>
</template>

<style scoped lang="scss">
.backBtn
{
    color: #0077ED;
    font-size: 14px;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s;
    cursor: pointer;
}
.backBtn:hover
{
    color: black;
    transition: color 0.3s;
}

</style>

<script>
export default {
    name: "BackButton",
    components: {
        
    },
    methods:
    {
        back()
        {
            this.$emit('back');
        }
    },
    data()
    {
        return {
            
        }
    }
}
</script>
