const utils =
{
    methods:
    {
        getFormattedDate(dateStr)
        {   
            var theDate = new Date(Date.parse(dateStr));
            const month = theDate.toLocaleString('default', { month: 'long' });
            return this.ordinal_suffix_of(theDate.getDay()) + " " + month + " " + theDate.getFullYear();
        },
        ordinal_suffix_of(i) {
            var j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return i + "<sup>st</sup>";
            }
            if (j == 2 && k != 12) {
                return i + "<sup>nd</sup>";
            }
            if (j == 3 && k != 13) {
                return i + "<sup>rd</sup>";
            }
            return i + "<sup>th</sup>";
        }
    }
}

export default utils;