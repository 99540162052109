<template>
    <div class="col-3 d-none d-lg-block" id="rightNav">
        
        <div class="row mb-3">
            <div class="col-12">
                <div class="input-group">
                    <input type="text" class="form-control desktopSearch" @keyup.enter="search" id="desktopSearch" ref="desktopSearch" placeholder="Search" :value="this.$store.state.searchTerm">
                    <div class="input-group-append clickable" @click="search" >
                        <i class="bi bi-search"></i>
                    </div>
                </div>
                
                
            </div>
        </div>
        <AskQuestion questionFormId="'rightNavQuestion'" hideIntroText="false"></AskQuestion>
    </div>
</template>

<style lang="scss" scoped>
@import "../assets/variables.scss";

#rightNav
{
    border-left: 1px solid #ededed;
    height: 100%;
    overflow: overlay;
}

#desktopSearch, .desktopSearch
{
    margin-top: 20px;
    border: 1px solid #B5C6D0;
    border-radius:$custom-border-radius;
    font-family: "Poppins";
    color: black;
    font-size: 12px;
    font-weight: 500;
}

.bi-search
{
    color: #B5C6D0;
    z-index: 5;
    font-size: 18px;
    right: 27px;
    top: 24px;
    position: relative;
}

</style>

<script>
import AskQuestion from "./AskQuestion.vue"

export default {
    name: 'RightNav',
    components: 
    {
        AskQuestion
    },
    methods:
    {
        search()
        {
            var searchTerm = this.$refs.desktopSearch.value;
            if (searchTerm.length > 1)
            {
                this.$emit('search', searchTerm);
                //console.log("Searching for: " + searchTerm);
                this.$emit('toggleSearch');
            }
            
        }
    }
}
</script>