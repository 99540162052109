<template>
    
    <div class="expertPanel mb-3 mt-3">
        <div class="row mt-3">
            <div class="col-12">
                <h1>Expert Panel</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <p>Get to know our Expert Panel by finding out more about their background and experience</p>
            </div>
        </div>

        <div class="row">
            
            <template v-for="item in this.$store.state.panelMembers" :key="item.nid[0].value">
                <div class="col-6 col-md-4 mb-4">
                    <div class="row justify-content-center">
                        <div class="col-9">
                            <img :src="item.field_profile_picture[0].url" alt="Martin Besser" class="img-fluid" />
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-10 col-md-10">
                            <h2>{{ item.title[0].value }}</h2>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-10 col-md-10">
                            <button @click="viewProfile(item.path[0].alias)">Profile</button>
                        </div>
                    </div>

                </div>
            </template>
        </div>
    </div>

</template>

<script>
export default {
    name: "ExpertPanel",
    methods:
    {
        viewProfile(profileName)
        {
            this.$router.push('/view-profile' + profileName);
        }
    },
}
</script>
<style lang="scss" scoped>
@import "../assets/variables.scss";

.expertPanel
{
    border: 2px solid #FF8800;
    border-radius: $custom-border-radius;
    background-color: white;
    padding: 1px;
    padding: 0.5rem 1rem;
}

.expertPanel h1
{
    color: black;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 700;
    text-align: left;
}

.expertPanel p
{
    font-size: 14px;
    font-family: "Poppins";
    color: black;
    text-align: left;
}

h2
{
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
}

button
{
    color: #0A77ED;
    border: 2px solid #0A77ED;
    border-radius: $custom-border-radius;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: white;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins";
    float: left;
    transition: background-color 0.3s, color 0.3s;
    width: 100%;
}

button:hover
{
    background-color: #0A77ED;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}
</style>