<template>
    
    <div class="footer mt-4">
        <hr />

        <div class="row">
            <div class="col-12 firstSection">
                <p>{{ abbreviations }}</p>

                
                <p><span style="color: black;">▼</span>This medicine is subject to additional monitoring. This will allow quick identification of new safety information. You can help by reporting any side effects you may experience. See <a href="https://www.mhra.gov.uk/yellowcard" target="_blank" style="color: #A7A7A7;">www.mhra.gov.uk/yellowcard</a> for how to report side effects.</p>
                

                <p>Legal Category: POM</p>

                <p>Marketing Authorisation (MA) number, quantities and price: EU/1/20/1476/001  £1,038.00 per 10ml vial.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="aeReporting centered">
                    <p>Adverse events should be reported. Reporting forms and information can be found at <a href="https://yellowcard.mhra.gov.uk/" target="_blank">yellowcard.mhra.gov.uk</a> Adverse events should also be reported to Novartis via <a href="mailto:uk.patientsafety@novartis.com">uk.patientsafety@novartis.com</a> or online through the pharmacovigilance intake (PVI) tool at <a href="https://www.report.novartis.com" target="_blank">www.report.novartis.com</a><br />
                    If you have a question about the product, please contact Medical Information on 01276 698370 or by email at <a href="mailto:medinfo.uk@novartis.com">medinfo.uk@novartis.com</a></p>
                </div>
            </div>
        </div>

        <!--<div class="row mt-3 centered">
            <div class="col-12">
                <p class="approvalCode">UK | July 2022 | XXXXXX</p>
            </div>
        </div>-->

        <div class="row footerLinks centered">
            <div class="col-12">
                <p><a @click="contactUs()" class="link">Contact</a> | <a @click="privacy()" class="link">Privacy notice</a> | <a @click="terms()" class="link">Terms of use</a> <!--| <a href="#" class="link">Accessibility</a>--></p>
            </div>
        </div>

        <div class="row centered">
            <div class="col-12">
                <p>&copy;2022 Novartis Pharmaceuticals UK Limited. All rights reserved.</p>

                <p>Novartis Pharmaceuticals UK Limited is a limited liability company registered in England and Wales under number&nbsp;119006.<br />Registered office: The WestWorks Building, White City Place, 195 Wood Lane, London, W12&nbsp;7FQ.</p>

                <!--<p>This site is intended for a UK healthcare professional audience and NHS relevant decision makers only.</p>-->
            </div>
        </div>

        <div class="row centered">
            <div class="col-12">
                <p class="approvalCode">UK | October 2022 | {{ this.approvalCode }}</p>
            </div>
        </div>

        <div class="row justify-content-center centered">
            <div class="col-8">
                <img src="../assets/img/novartis-logo.png" alt="Novartis Logo" class="img-fluid" />       
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterView",
    components:
    {

    },
    data()
    {
        return {
            abbreviations: "Abbreviations: A&E, Accident & Emergency; ASH, American Society of Haemotology; HCPs, healthcare professionals; LHT, Local Haemotolgy Team; MDT, multidisciplinary team; NHS, National Health Service; NICE, National Institue for Health and Care Excellence; SCD, sickle cell disease; SHT, Specialist Haemotology Team; SOP, standard operating process; UCH, University College Hospital; VOC(s), vaso-occlusive crisis(es).",
            approvalCode: ""
        }
    },
    methods:
    {
        contactUs()
        {
            this.$router.push('/contact-us/');
        },
        privacy()
        {
            this.$router.push('/privacy-notice/');
        }, 
        terms()
        {
            this.$router.push('/terms-of-use/');
        },
        setApprovalCode(new_code)
        {
            this.approvalCode = new_code;
        }
    },
    computed:
    {
        getPodcast()
        {
            return this.$store.getters.getPodcastFromAlias(this.$route.params.id);
        },
    }, 
    watch: 
    {
        $route(to, from)
        {
            console.log("Route parameters: " + this.$route.params.id);
            console.log("Route: " + this.$route.path);

            if (this.$route.path == "/" || this.$route.path.includes("intro-animation"))
            {
                this.approvalCode = "230133";
            }
            else if (this.$route.path.includes("expert-panel"))
            {
                this.approvalCode = "230143";
            }
            else if (this.$route.path.includes("contact-us"))
            {
                this.approvalCode = "231991";
            }
            else if (this.$route.path.includes("privacy-notice"))
            {
                this.approvalCode = "231994";
            }
            else if (this.$route.path.includes("terms-of-use"))
            {
                this.approvalCode = "231995";
            }
            else if (this.$route.params.id == "dr-martin-besser")
            {
                this.approvalCode = "230146";
            }
            else if (this.$route.params.id == "preparation")
            {
                this.approvalCode = "230139";
            }
            else if (this.$route.params.id == "managing")
            {
                this.approvalCode = "230137";
            }
            else if (this.$route.params.id == "monitoring")
            {
                this.approvalCode = "230135";
            }
            else if (this.$route.params.id == "experience")
            {
                this.approvalCode = "230141";
            }
            
            else
            {
                this.approvalCode = "230133";
            }
            console.log("To: " + to.name + " // From: " + from.name + " // ID: " + this.$route.params.id);
            
            /*if (to.name == "home")
            {
                this.abbreviations = "Abbreviations: SCD, sickle cell disease; VOC, vaso-occlusive crisis.";
            }
            else if (this.$route.params.id == "amy-impact-voc" || this.$route.params.id == "amy-patient-support")
            {
                this.abbreviations = "Abbreviations: SCD, sickle cell disease; VOCs, vaso-occlusive crises.";
            }
            else if (this.$route.params.id == "perla-importance-uptake-gathering-realworld")
            {
                this.abbreviations = "Abbreviations: NICE, National Institute for Health and care Excellence; NHS, National Health Service; SCD, sickle cell disease; UCH, University College Hospital; VOC, vaso-occlusive crisis.";
            }
            else if (this.$route.params.id == "perla-preparing-new-treatment-large-clinics")
            {
                this.abbreviations = "Abbreviations: SCD, sickle cell disease; UCH, University College Hospital; VOC, vaso-occlusive crisis.";
            }
            else
            {*/
                this.abbreviations = "Abbreviations: A&E, Accident & Emergency; ASH, American Society of Haemotology; HCPs, healthcare professionals; LHT, Local Haemotolgy Team; MDT, multidisciplinary team; NHS, National Health Service; NICE, National Institue for Health and Care Excellence; SCD, sickle cell disease; SHT, Specialist Haemotology Team; SOP, standard operating process; UCH, University College Hospital; VOC(s), vaso-occlusive crisis(es).";
            //}
        }

    }
}
</script>

<style lang="scss" scoped>
p
{
    color: #A7A7A7;
    font-size: 10px;
    font-family: "Poppins";
    word-break: break-word;
    text-align: center;
}

.firstSection p
{
    text-align: center;
}

.aeReporting
{
    padding: 10px;
    border: 1px solid #A7A7A7;
}

a
{
    color:#0A77ED;
}
.link
{
    text-decoration: underline;
    color:#0A77ED;
    cursor: pointer;
}

.approvalCode
{
    color: #A7A7A7;
    text-align: center;
}
p
{
    text-align: center;
}

.footerLinks a
{
    color:#0A77ED;
    text-decoration: none;
}

.footerLinks
{
    margin-top: 20px;
}

.centered p
{
    text-align: center !important;
}
</style>