<template>

    <div class="miniMediaPlayer" :class="{active: playerVisible}">
        <div class="row justify-content-end">
            
            <div class="col-10 miniMediaInner">
                
                
                <div class="row">
                    <div class="col-2 align-self-start" style="text-align: left;">
                        <img src="../assets/img/close-btn.png" alt="Close Button" @click="closePlayer" class="img-fluid closeBtn clickable" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 col-9">
                        <p v-html="audioTitle"></p>
                    </div>
                    <div class="col-lg-2 col-3">
                        <img src="../assets/img/pause.png" @click="pause()" style="width: 59px;" class="clickable img-fluid" v-if="this.$store.state.audioIsPlaying" />
                        <img src="../assets/img/play.png" @click="play()" style="width: 59px;" class="clickable img-fluid" v-if="!this.$store.state.audioIsPlaying" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input type="range" @mousedown="this.pause()" @change="changeRange" :style="{backgroundSize: calculateRangeSize()}" ref="rangeSlider" step="0.01"  min="0" :value="this.$store.state.audioPosition" :max="this.$store.state.audioDuration">
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped lang="scss">
    .miniMediaInner
    {
        background-color: rgba(255, 255, 255, 0.95);
        border-top-left-radius: 18px;
        min-height: 80px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 13px;
        padding-bottom: 20px;
        -webkit-box-shadow: -2px 2px 14px -3px rgba(0,0,0,0.74); 
        box-shadow: -2px 2px 14px -3px rgba(0,0,0,0.74);
    }

    .closeBtn
    {
        max-width: 21px;
        text-align: left;
        margin-left: -28px;
    }

    .miniMediaPlayer
    {
        position: sticky;
        bottom: -300px;
        right: -500px;
        width: 100%;
        opacity: 0;
        transition: opacity 0.3s, bottom 0.3s;
    }

    .miniMediaPlayer.active
    {
        bottom: 80px;
        opacity: 1;
        right: 0px;
        transition: opacity 0.3s, bottom 0.3s;
    }

    p
    {
        font-family: "Poppins";
        font-size: 12px;
        color: black;
        text-align: left;
    }
</style>

<script>

export default {
  name: 'MiniMediaPlayer',
  props:
  {
    audioTitle: String
  },
  data()
  {
    return {
        playerVisible: false
    }
  },
  methods:
  {
        calculateRangeSize()
        {
            var percentage = (this.$store.state.audioPosition) * 100 / (this.$store.state.audioDuration);
            return percentage + "% 100%";
        },
        play()
        {
            this.$emit('playAudio');
        },
        pause()
        {
            this.$emit('pauseAudio');
        },
        closePlayer()
        {
            this.$emit('pauseAudio');
            this.playerVisible = false;
        },
        changeRange()
        {
            console.log("Range changed " + event + " / " + this.$refs.rangeSlider.value);
            this.$emit('setAudioPosition', this.$refs.rangeSlider.value);
        },
        showPlayer()
        {
            this.playerVisible = true;
        },
        hidePlayer()
        {
            this.playerVisible = false;
        }
  }
}

</script>