<template>
    
<div class="card mb-3" :class="{minWidth: minWidth}">
    <!--<img src="..." class="card-img-top" alt="...">-->
    <div class="card-header">
        <div class="row justify-content-between">
            <div class="col-lg-5 col-8" style="display: flex; flex-direction: column;">
                <div class="row">
                    <div class="col-12">
                        <p class="align-top" v-html="date"></p>
                    </div>
                </div>
                <div class="row align-bottom" style="margin-top: auto;">
                    <div class="col-12">
                        <h2 class="topic" v-html="category"></h2>
                    </div>
                </div>
            </div>
            <div class="col-4 col-md-3">
                <img :src="profilePhoto" class="img-fluid" :alt="presenter" />
            </div>
        </div>
    </div>

    <div class="card-body">
        <h1 class="card-title" v-html="presenter"></h1>
        <p class="card-text" v-html="body"></p>
    </div>
    <div class="card-footer mb-4">
        <div class="row justify-content-between">
            <div class="col-lg-4 col-6">
                <button @click="listen()">Listen <i class="bi bi-arrow-right"></i></button>
            </div>

            <div class="col-lg-4 col-6 iconsRow">
                <i class="bi bi-info-circle-fill clickable" @click="viewPresenter()"></i> &nbsp;
                <i class="bi bi-heart-fill"></i> <span class="likeCount" v-html="likeCount"></span> &nbsp;
                <i class="bi bi-stopwatch"></i> <span class="duration" v-html="duration"></span>
            </div>
        </div>
    </div>
</div>

</template>

<style lang="scss" scoped>
@import "../assets/variables.scss";

.card
{
    background-image: linear-gradient(180deg, #ffb100, #ff8800);
    border: none;
    border-radius: $custom-border-radius;
}

.minWidth
{
    min-width: 120% !important;
}

.card-header, .card-footer
{
    border: none;
    background-color: transparent;
}

.card-header .col-5
{
    display: flex;
}

.card-header p
{
    color: white;
    font-size: 9px;
    font-weight: 500;
    font-family: "Poppins";
    text-align: left;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.topic
{
    font-family: "Poppins";
    font-size: 10px;
    font-weight: 700;
    color: white;
    background-color: black;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: $custom-border-radius;
    text-align: left;
    height: 23px;
    align-self: flex-end;
    display: table;
}

.card-body .card-title
{
    text-align: left;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 700;
    color: white;
}

.card-body .card-text
{
    text-align: left;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    color: black;
}

button
{
    background-color: white;
    border: none;
    border-radius: $custom-border-radius;
    color: $blue-color;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 0px;
    padding-bottom: 3px;
    transition: background-color 0.3s, color 0.3s;
    float: left;
}

button:hover
{
    background-color: #0A77ED;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}

button i
{
    font-size: 24px;
    top: 4px;
    position: relative;
}

.iconsRow
{
    margin-top: auto;
    text-align: right;
}
.iconsRow, .iconsRow i
{
    color: white;
}

.clickable
{
    cursor: pointer;
    transition: opacity 0.2s;
}

.clickable:hover
{
    opacity: 0.8;
    transition: opacity 0.2s;
}

.likeCount, .duration
{
    display: unset;
    top: unset;
    right: unset;
    position: unset;
}
</style>

<script>

export default {
  name: 'PodcastCard',
  components: {
    
  },
  methods:
  {
    listen()
    {
        this.$router.push('/media-player' + this.path);
    },
    viewPresenter()
    {
        this.$router.push('/view-profile' + this.presenterPath);
    }
  },
    props:
    {
        date: String,
        profilePhoto: String,
        category: String,
        presenter: String,
        body: String,
        path: String,
        presenterPath: String,
        id: Number,
        duration: String,
        likeCount: Number,
        minWidth: Boolean
    }
}
</script>
